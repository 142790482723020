export default [
  {
    path: '/simi-network',
    name: 'simi-network',
    component: () => import('@/views/simiNetworkDashboard/MainDashboard.vue'),
    meta: {
      pageTitle: 'SIMI Network',
      breadcrumb: [
        {
          text: 'SIMI Network',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/simi-network/screen/:deviceId',
    name: 'simi-network-single-device-user',
    component: () => import('@/views/simiNetworkDashboard/SingleScreenDashboard.vue'),
    meta: {
      pageTitle: 'Screen Details',
      breadcrumb: [
        {
          text: 'SIMI Network',
          to: '/simi-network',
        },
        {
          text: 'Screen Details',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/simi-network/venue/:venueId',
    name: 'simi-network-single-venue-user',
    component: () => import('@/views/simiNetworkDashboard/viewVenueDashboard.vue'),
    meta: {
      pageTitle: 'Venue Details',
      breadcrumb: [
        {
          text: 'SIMI Network',
          to: '/simi-network',
        },
        {
          text: 'Venue Details',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/simi-network/:venueId/device/:deviceId',
    name: 'simi-network-single-device-details',
    component: () => import('@/views/simiNetworkDashboard/SingleScreenDashboard.vue'),
    meta: {
      pageTitle: 'Venue Details',
      breadcrumb: [
        {
          text: 'Venue Details',
          to: '/simi-network/venue/:venueId',
          key: 'venueId',
          isDynamic: true,
        },
        {
          text: 'Screen Details',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/simi-network/external-ads',
    name: 'simi-network-pmp-ads-edit',
    component: () => import('@/components/common/CardCreateExternalAd.vue'),
    meta: {
      pageTitle: 'Create Campaign',
      breadcrumb: [
        {
          text: 'SIMI Network',
          to: '/simi-network',
        },
        {
          text: 'Create Campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/simi-network/venue/:venueId/external-ads',
    name: 'simi-network-venue-external',
    component: () => import('@/components/common/CardCreateExternalAd.vue'),
    meta: {
      pageTitle: 'Create Campaign',
      breadcrumb: [
        {
          text: 'Venue Details',
          to: '/simi-network/venue/:venueId',
          key: 'venueId',
          isDynamic: true,
        },
        {
          text: 'Create Campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/simi-network/:venueId/device/:deviceId/external-ads',
    name: 'simi-network-venue-screen-external',
    component: () => import('@/components/common/CardCreateExternalAd.vue'),
    meta: {
      pageTitle: 'Create Campaign',
      breadcrumb: [
        {
          text: 'Screen Details',
          to: '/simi-network/:venueId/device/:deviceId',
          key: ['venueId', 'deviceId'],
          isMultiple: true,
        },
        {
          text: 'Create Campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/simi-network/screen/:deviceId/external-ads',
    name: 'simi-network-single-device-external',
    component: () => import('@/components/common/CardCreateExternalAd.vue'),
    meta: {
      pageTitle: 'Create Campaign',
      breadcrumb: [
        {
          text: 'Screen Details',
          to: '/simi-network/screen/:deviceId',
          key: 'deviceId',
          isDynamic: true,
        },
        {
          text: 'Create Campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
