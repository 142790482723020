/* eslint-disable no-unused-vars */
import Vue from 'vue'
import { showToast } from '@/common/global/functions'

export default {
  namespaced: true,
  state: {
    selectedDevices: [],
    selectedVenuesOnDashboard: [],
  },
  mutations: {
    SET_SELECTED_DEVICES(state, payload) {
      state.selectedDevices = payload
    },
    SET_SELECTED_DEVICES_ON_DASHBOARD(state, payload) {
      state.selectedVenuesOnDashboard = payload
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async getAllPmpCampaignsByUser({ commit }, { limit, offset }) {
      try {
        const response = await Vue.prototype.$axios.get(`/pmp-ads?limit=${limit}&offset=${offset}&search_query=`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getAllUserPmpCampaignsByAdmin({ commit }, { groupId, limit, offset }) {
      try {
        const response = await Vue.prototype.$axios.get(`/admin/pmp-ad/user-has-group/${groupId}?limit=${limit}&offset=${offset}&search_query=`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createNewPMPAdsByAdmin({ commit }, { postData, groupId }) {
      try {
        const response = await Vue.prototype.$axios.post('/admin/pmp-ad', postData, { headers: { userHasGroupId: groupId } })
        if (response.data.statusCode === 201) {
          showToast('Add External Ad', 'External Ad has been added successfully!')
        }
        return true
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createNewPMPAdsByUser({ commit }, { postData }) {
      try {
        const response = await Vue.prototype.$axios.post('/pmp-ads', postData)
        if (response.data.statusCode === 201) {
          showToast('Add External Ad', 'External Ad has been added successfully!')
        }
        return true
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getAvailableDevicesByAdmin({ commit }, { postData }) {
      try {
        const response = await Vue.prototype.$axios.post('/admin/pmp-ad/get-available-devices', postData)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getAvailableDevicesByUser({ commit }, { postData }) {
      try {
        const response = await Vue.prototype.$axios.post('/pmp-ads/get-available-devices', postData)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getOnePmpAdByUser({ commit }, { id }) {
      try {
        const response = await Vue.prototype.$axios.get(`/pmp-ads/${id}`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getOnePmpAdByAdmin({ commit }, { id, userHasGroupId }) {
      try {
        const response = await Vue.prototype.$axios.get(`/admin/pmp-ad/find/${id}`, { headers: { userHasGroupId } })
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async editPmpAdsDataByAdmin({ commit }, { PmpAdId, postData, userHasGroupId }) {
      try {
        const response = await Vue.prototype.$axios.put(`admin/pmp-ad/${PmpAdId}`, postData, { headers: { userHasGroupId } })
        if (response.data.statusCode === 200) {
          showToast('Edit External Ad', 'External Ad has been updated successfully!')
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async editPmpAdsDataByUser({ commit }, { PmpAdId, postData, userHasGroupId }) {
      try {
        const response = await Vue.prototype.$axios.put(`/pmp-ads/${PmpAdId}`, postData)
        if (response.data.statusCode === 200) {
          showToast('Edit External Ad', 'External Ad has been updated successfully!')
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw error
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getAllPmpCampaignsOfAllUsersByAdmin({ commit }, { limit, offset, status }) {
      try {
        const response = await Vue.prototype.$axios.get(`/admin/pmp-ad/get-all-pmp-ads?limit=${limit}&offset=${offset}&search_query=&status=${status}`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async rejectPmpAdByAdmin({ commit }, { id }) {
      try {
        const response = await Vue.prototype.$axios.put(`/admin/pmp-ad/reject-pmp-ad/${id}`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async approvePmpAdByAdmin({ commit }, { id }) {
      try {
        const response = await Vue.prototype.$axios.put(`/admin/pmp-ad/approve-pmp-ad/${id}`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },

    // eslint-disable-next-line no-unused-vars
    async getUserImpressionCalculationPmpScheduler({ commit }, { pmpAdId }) {
      try {
        const response = await Vue.prototype.$axios.get(`/pmp-ads/scheduler/impression-calculation/analytics/${pmpAdId}`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },

    async getAdminImpressionCalculationPmpScheduler({ commit }, { pmpAdId }) {
      try {
        const response = await Vue.prototype.$axios.get(`admin/pmp-ad/scheduler/impression-calculation/analytics/${pmpAdId}`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },

    async getAdCategories({ commit }) {
      try {
        const response = await Vue.prototype.$axios.get('/ad-category')
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },
    async getAdminAdCategories({ commit }) {
      try {
        const response = await Vue.prototype.$axios.get('/admin/ad-category')
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },
    async getAllPmpRefundList({ commit }, { limit, offset, status }) {
      try {
        const response = await Vue.prototype.$axios.get(`admin/pmp-ad/get-pmp-ad-refunds?offset=${offset - 1}&limit=${limit}&search_query=&status=${status}`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },

    async getAllPmpAdRevenueForVenueOwnerList({ commit }, { limit, offset, status }) {
      try {
        const response = await Vue.prototype.$axios.get(`admin/pmp-ad/get-venue-revenue-share?offset=${offset - 1}&limit=${limit}&search_query=&status=${status}`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },

    async getAllPmpAdRevenueForAgencyOwnerList({ commit }, { limit, offset, status }) {
      try {
        const response = await Vue.prototype.$axios.get(`admin/pmp-ad/get-agency-revenue-share?offset=${offset - 1}&limit=${limit}&search_query=&status=${status}`)
        if (response.data.statusCode === 200) {
          return response.data.data
        }
        return null
      } catch (error) {
        showToast('Error', error.response.data.message, 'danger')
        throw new Error(error)
      }
    },

  },
  getters: {
    getSelectedDevices: state => state.selectedDevices,
    getSelectedVenuesOnDashboard: state => state.selectedVenuesOnDashboard,
  },
}
