export default [
  {
    path: '/admin-billing-details',
    name: 'admin-billing-details',
    component: () => import('@/views/admin/billingDetails/index.vue'),
    meta: {
      pageTitle: 'Billing Details',
      breadcrumb: [
        {
          text: 'Billing Details',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
