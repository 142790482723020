export default [
  {
    path: '/groups',
    name: 'groups',
    component: () => import('@/views/admin/AllGroups.vue'),
    meta: {
      pageTitle: 'Businesses',
      breadcrumb: [
        {
          text: 'Businesses',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/groups/:userId',
    name: 'user',
    component: () => import('@/views/admin/user/User.vue'),
    meta: {
      pageTitle: 'Businesses',
      breadcrumb: [
        {
          text: 'Businesses',
          to: '/groups',
        },
        {
          text: 'Business',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },

  {
    path: '/users/:userId/user-profile',
    name: 'user-profile',
    component: () => import('@/components/user/UserProfile.vue'),
    meta: {
      pageTitle: 'Businesses',
      breadcrumb: [
        {
          text: 'Businesses',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'Profile',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-banner-ads',
    name: 'user-banner-ads',
    component: () => import('@/views/admin/user/UserBannerAds.vue'),
    meta: {
      pageTitle: 'Internal Ad campaigns',
      breadcrumb: [
        {
          text: 'Businesses',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'Internal Ad Campaigns',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-banner-ads/add-campaigns',
    name: 'user-banner-ads-add',
    component: () => import('@/views/bannerAds/AddCampaigns.vue'),
    meta: {
      navActiveLink: 'user',
      pageTitle: 'Add Campaign',
      breadcrumb: [
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'Internal Ad campaign',
          to: '/users/:userId/user-banner-ads',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: 'Add campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-banner-ads/edit-ads/:id',
    name: 'user-banner-ads-edit',
    component: () => import('@/components/bannerads/EditBannerCampaign.vue'),
    meta: {
      navActiveLink: 'user',
      pageTitle: 'Edit Campaign',
      breadcrumb: [
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'Internal Ad campaign',
          to: '/users/:userId/user-banner-ads',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: 'Edit campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-banner-ads/view-ads/:id',
    name: 'user-banner-ads-view',
    component: () => import('@/views/bannerAds/ViewCampaign.vue'),
    meta: {
      navActiveLink: 'user',
      pageTitle: 'View Campaign',
      breadcrumb: [
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'Internal Ad campaign',
          to: '/users/:userId/user-banner-ads',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: 'View campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-group-members',
    name: 'user-group-members',
    component: () => import('@/views/admin/user/UserGroupMembers.vue'),
    meta: {
      pageTitle: 'Businesses',
      breadcrumb: [
        {
          text: 'Businesses',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'Business Members',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-media-storage',
    name: 'user-media-storage',
    component: () => import('@/views/admin/user/UserMediaStorage.vue'),
    meta: {
      pageTitle: 'Businesses',
      breadcrumb: [
        {
          text: 'Businesses',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'Media Storage',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-venues-group',
    name: 'user-venues',
    component: () => import('@/views/venues/index.vue'),
    meta: {
      pageTitle: 'Venues',
      breadcrumb: [
        {
          text: 'Businesses',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'Venues',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/:venueId/user-devices-group',
    name: 'user-devices',
    component: () => import('@/views/admin/user/UserDevices.vue'),
    meta: {
      pageTitle: 'Screens',
      breadcrumb: [
        {
          text: 'Business',
          to: '/groups/:userId',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: 'Venues',
          to: '/users/:userId/user-venues-group',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: 'Screens',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/:venueId/single-device/:deviceId/:deviceName',
    name: 'single-device-admin',
    component: () => import('@/views/devices/SingleDevice.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Venues',
          to: '/users/:userId/user-venues-group',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: 'Screens',
          to: '/users/:userId/:venueId/user-devices-group',
          key: ['userId', 'venueId'],
          isMultiple: true,
        },
        {
          text: '',
          active: true,
        },
      ],
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (to.name === 'single-device-admin') {
      /* eslint-disable */
        to.meta.pageTitle = to.params.deviceName
        to.meta.breadcrumb[2].text=to.params.deviceName
        /* eslint-enable */
        next()
      }
    },
  },
  {
    path: '/users/:userId/non-configured/:deviceId/:deviceName',
    name: 'single-device-admin-nonconfig',
    component: () => import('@/views/devices/SingleDevice.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Non-Configured Devices',
          to: '/nonconfig',
        },
        {
          text: '',
          active: true,
        },
      ],
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (to.name === 'single-device-admin-nonconfig') {
      /* eslint-disable */
        to.meta.pageTitle = to.params.deviceName
        to.meta.breadcrumb[1].text=to.params.deviceName
        /* eslint-enable */
        next()
      }
    },
  },
  {
    path: '/users/:userId/all-screens/:deviceId/:deviceName',
    name: 'single-device-admin-allScreens',
    component: () => import('@/views/devices/SingleDevice.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'All Screens',
          to: '/screenList',
        },
        {
          text: '',
          active: true,
        },
      ],
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (to.name === 'single-device-admin-allScreens') {
      /* eslint-disable */
        to.meta.pageTitle = to.params.deviceName
        to.meta.breadcrumb[1].text=to.params.deviceName
        /* eslint-enable */
        next()
      }
    },
  },
  {
    path: '/user/:userId/:venueId/:deviceId/hivestack/config/:deviceName',
    name: 'device-hivestack-config',
    component: () => import('@/components/admin/HiveStackConfiguration.vue'),
    meta: {
      pageTitle: 'Hive Stack',
      breadcrumb: [
        {
          text: 'Screens',
          to: '/users/:userId/:venueId/user-devices-group',
          key: ['userId', 'venueId'],
          isMultiple: true,
        },
        {
          text: '',
          to: '/users/:userId/:venueId/single-device/:deviceId/:deviceName',
          key: ['userId', 'venueId', 'deviceId', 'deviceName'],
          isMultiple: true,
        },
        {
          text: 'HiveStack',
          active: true,
        },
      ],
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (to.name === 'device-hivestack-config') {
      /* eslint-disable */
        to.meta.breadcrumb[1].text = to.params.deviceName
        /* eslint-enable */
        next()
      }
      // console.log(from)
    },
  },
  {
    path: '/user/:userId/:venueId/:deviceId/vistar/config/:deviceName',
    name: 'device-vistar-config',
    component: () => import('@/components/admin/VistarConfiguration.vue'),
    meta: {
      pageTitle: 'Vistar',
      breadcrumb: [
        {
          text: 'Screens',
          to: '/users/:userId/:venueId/user-devices-group',
          key: ['userId', 'venueId'],
          isMultiple: true,
        },
        {
          text: '',
          to: '/users/:userId/:venueId/single-device/:deviceId/:deviceName',
          key: ['userId', 'venueId', 'deviceId', 'deviceName'],
          isMultiple: true,
        },
        {
          text: 'Vistar',
          active: true,
        },
      ],
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (to.name === 'device-vistar-config') {
      /* eslint-disable */
        to.meta.breadcrumb[1].text = to.params.deviceName
        /* eslint-enable */
        next()
      }
      // console.log(from)
    },
  },
  {
    path: '/report/single-device/:id/:name',
    name: 'single-table-admin',
    component: () => import('@/views/SingleScreen.vue'),
    meta: {
      pageTitle: 'Single Screen',
      breadcrumb: [
        {
          text: 'Report',
          to: '/report',
        },
        {
          text: '',
          active: true,
        },
      ],
      requireAuth: true,
    },
    beforeEnter(to, from, next) {
      if (to.name === 'single-table-admin') {
      /* eslint-disable */
        to.meta.pageTitle = to.params.name
        to.meta.breadcrumb[1].text =to.params.name
        /* eslint-enable */
        next()
      }
      // console.log(from)
    },
  },
  {
    path: '/users/:userId/user-pmp-ads',
    name: 'user-pmp-ads',
    component: () => import('@/views/admin/user/UserPmpAds.vue'),
    meta: {
      pageTitle: 'External Ads',
      breadcrumb: [
        {
          text: 'Businesses',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'External Ads',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-pmp-ads/create-ads',
    name: 'user-pmp-ads-add',
    component: () => import('@/views/pmpAds/CreatePmpAds.vue'),
    meta: {
      navActiveLink: 'user',
      pageTitle: 'Add External Ad',
      breadcrumb: [
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'External Ads',
          to: '/users/:userId/user-pmp-ads',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: 'Add External Ad',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-pmp-ads/edit-ads/:id',
    name: 'user-pmp-ads-edit',
    component: () => import('@/components/pmp-ads/EditPmpAds.vue'),
    meta: {
      navActiveLink: 'user',
      pageTitle: 'Edit External Ad',
      breadcrumb: [
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'External Ads',
          to: '/users/:userId/user-pmp-ads',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: 'Edit External Ad',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-pmp-ads/view-ads/:user_type/:id',
    name: 'user-pmp-ads-view',
    component: () => import('@/views/pmpAds/ViewPmpAd.vue'),
    meta: {
      navActiveLink: 'user',
      pageTitle: 'View External Ad',
      breadcrumb: [
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'External Ads',
          to: '/users/:userId/user-pmp-ads',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: 'View External Ad',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-pmp-ads/:id/analytics',
    name: 'pmp-ads-scheduler-analytics',
    component: () => import('@/views/pmpAds/PmpAdSchedulerAnalytics.vue'),
    meta: {
      navActiveLink: 'pmp-ads-scheduler-analytics',
      pageTitle: 'Scheduler',
      breadcrumb: [
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'External Ads',
          to: '/users/:userId/user-pmp-ads',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: 'Scheduler',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-brands',
    name: 'user-brands',
    component: () => import('@/views/brands/index.vue'),
    meta: {
      pageTitle: 'Brands',
      breadcrumb: [
        {
          text: 'Businesses',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'Brands',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/groups/:userId/screen/:deviceName/:deviceId',
    name: 'user-single-screen-details',
    component: () => import('@/views/devices/SingleDevice.vue'),
    meta: {
      pageTitle: 'Businesses',
      breadcrumb: [
        {
          text: 'Businesses',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'Screen Details',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/groups/:userId/screen/:deviceName/:deviceId/config',
    name: 'user-screen-ssp-config',
    component: () => import('@/components/admin/VistarConfiguration.vue'),
    meta: {
      pageTitle: 'Vistar',
      breadcrumb: [
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'Screen Details',
          to: '/groups/:userId/screen/:deviceName/:deviceId',
          key: ['userId', 'deviceName', 'deviceId'],
          isMultiple: true,
        },
        {
          text: 'Vistar',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/groups/:userId/venues/:venueId',
    name: 'user-single-venue-details',
    component: () => import('@/views/simiNetworkDashboard/viewVenueDashboard.vue'),
    meta: {
      pageTitle: 'Venue Details',
      breadcrumb: [
        {
          text: 'Businesses',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Business',
          to: '/groups/:userId',
        },
        {
          text: 'Venue Details',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/groups/:userId/screen/:deviceId/:deviceName',
    name: 'user-single-venue-screen-details',
    component: () => import('@/views/simiNetworkDashboard/SingleScreenDashboard.vue'),
    meta: {
      pageTitle: 'Screen Details',
      breadcrumb: [
        {
          isMultiple: true,
          text: 'Venue Details',
          to: '/groups/:userId/venues/:venueId',
          key: ['userId', 'venueId'],
        },
        {
          text: 'Screen Details',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/groups/external',
    name: 'admin-user-external',
    component: () => import('@/components/common/CardCreateExternalAd.vue'),
    meta: {
      pageTitle: 'Create Campaign',
      breadcrumb: [
        {
          text: 'Businesses',
          to: '/groups',
        },
        {
          text: 'Create Campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/groups/:userId/venue/:venueId',
    name: 'admin-user-venue-details',
    component: () => import('@/views/devices/index.vue'),
    meta: {
      pageTitle: 'Venue Details & All Screens',
      breadcrumb: [
        {
          text: 'user',
          to: '/groups/:userId',
          key: 'userId',
          isDynamic: true,
        },
        {
          text: 'Venue Details & All Screens',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/groups/:userId/venue/:venueId/:deviceName/:deviceId',
    name: 'user-venue-single-screen-details',
    component: () => import('@/views/devices/SingleDevice.vue'),
    meta: {
      pageTitle: 'Screen Details',
      breadcrumb: [
        {
          text: 'Venue Details & All Screens',
          to: '/groups/:userId/venue/:venueId',
          key: ['userId', 'venueId'],
          isMultiple: true,
        },
        {
          text: 'Screen Details',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
