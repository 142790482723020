export default [
  {
    path: '/chat',
    name: 'chat-app',
    component: () => import('@/views/chat/index.vue'),
    meta: {
      pageTitle: 'Chat',
      breadcrumb: [
        {
          text: 'Chat',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
