export default [
  {
    path: '/',
    name: 'dashboard',
    props: route => ({ id: route.query.groupId }),
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      pageTitle: 'My Dashboard',
      breadcrumb: [
        {
          // text: 'Dashboard',
          active: true,
          icon: 'HomeIcon',
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/external-schedular-dashboard/:id',
    name: 'external-schedular-dashboard',
    component: () => import('@/views/pmpAds/PmpAdSchedulerAnalytics.vue'),
    meta: {
      navActiveLink: 'external-schedular-dashboard',
      pageTitle: 'Scheduler',
      breadcrumb: [
        {
          text: 'Dashboard',
          to: '/',
        },
        {
          text: 'Scheduler',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/dashboard/device/:id',
    name: 'dashboard-single-device-user',
    component: () => import('@/views/devices/SingleDevice.vue'),
    meta: {
      pageTitle: 'Screen Details',
      breadcrumb: [
        {
          text: 'Screen Details',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/dashboard/external-ads',
    name: 'dashboard-pmp-ads-edit',
    component: () => import('@/components/common/CardCreateExternalAd.vue'),
    meta: {
      pageTitle: 'Create Campaign',
      breadcrumb: [
        {
          text: 'Create Campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/dashboard/venue/:venueId',
    name: 'dashboard-single-venue',
    component: () => import('@/views/simiNetworkDashboard/viewVenueDashboard.vue'),
    meta: {
      pageTitle: 'Venue Details',
      breadcrumb: [
        {
          text: 'Venue Details',
          to: '/dashboard/venue-details',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/dashboard/:venueId/device/:deviceId',
    name: 'dashboard-venue-device-details',
    component: () => import('@/views/simiNetworkDashboard/SingleScreenDashboard.vue'),
    meta: {
      pageTitle: 'Screen Details',
      breadcrumb: [
        {
          isDynamic: true,
          text: 'Venue Details',
          key: 'venueId',
          to: '/dashboard/venue/:venueId',
        },
        {
          text: 'Screen Details',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },

  {
    path: '/dashboard/:venueId/device/:deviceId/external-ads',
    name: 'dashboard-venue-device-externalAds',
    component: () => import('@/components/common/CardCreateExternalAd.vue'),
    meta: {
      pageTitle: 'Screen Details',
      breadcrumb: [
        {
          to: '/dashboard/:venueId/device/:deviceId',
          text: 'Screen Details',
          key: ['venueId', 'deviceId'],
          isMultiple: true,
        },
        {
          text: 'Create Campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },

  {
    path: '/dashboard/:venueId/external-ads',
    name: 'dashboard-venue-externals',
    component: () => import('@/components/common/CardCreateExternalAd.vue'),
    meta: {
      pageTitle: 'Create Campaign',
      breadcrumb: [
        {
          isDynamic: true,
          text: 'Venue Details',
          key: 'venueId',
          to: '/dashboard/venue/:venueId',
        },
        {
          text: 'Create Campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/dashboard/venue/:venueId/external-ads',
    name: 'dashboard-venue-details-externals',
    component: () => import('@/components/common/CardCreateExternalAd.vue'),
    meta: {
      pageTitle: 'Create Campaign',
      breadcrumb: [
        {
          text: 'Venue Details',
          to: '/dashboard/venue/:venueId',
          key: 'venueId',
          isDynamic: true,
        },
        {
          text: 'Create Campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/dashboard/:venueId/device/:deviceId/external-ads',
    name: 'dashboard-venue-device-externals',
    component: () => import('@/components/common/CardCreateExternalAd.vue'),
    meta: {
      pageTitle: 'Create Campaign',
      breadcrumb: [
        {
          isMultiple: true,
          text: 'Screen Details',
          to: '/dashboard/:venueId/device/:deviceId',
          key: ['venueId', 'deviceId'],
        },
        {
          text: 'Create Campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
