import Vue from 'vue'
import VueRouter from 'vue-router'
// import { getAuth, signOut } from 'firebase/auth'
import store from '@/store'
import dashboard from '@/router/pages/dashboard'
import others from '@/router/pages/others'
import profiles from '@/router/pages/profiles'
import targetAds from '@/router/pages/targetAds'
import pmpAds from '@/router/pages/pmpAds'
import mediaStorage from '@/router/pages/mediaStorage'
import devices from '@/router/pages/devices'
import report from '@/router/pages/report'
import bannerAds from '@/router/pages/bannerAds'
import venues from '@/router/pages/venues'
import academy from '@/router/pages/academy'
import support from '@/router/pages/support'
import revenueSharing from './pages/revenueSharing'
import simiNetwork from '@/router/pages/simiNetwork'
import { adminRoutes } from '@/router/pages/admin'
import { getInstance } from '@/auth/auth0'
import creativeStudio from './creativeStudio'
import brands from './pages/brands'
import { findAuth0SessionKey } from '@/common/global/functions'
import chat from './pages/chat'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...adminRoutes,
    ...dashboard,
    ...profiles,
    ...mediaStorage,
    ...creativeStudio,
    ...simiNetwork,
    ...revenueSharing,
    ...brands,
    ...devices,
    ...bannerAds,
    ...targetAds,
    ...pmpAds,
    ...venues,
    ...report,
    ...academy,
    ...support,
    ...others,
    ...chat,
  ],
})
const setSelectedGroupId = async groupId => {
  await store.commit('persistedStore/SET_GROUP_ID_FROM_QUERY', groupId)
}
const fetchUserDataIfRequired = async () => {
  const auth0Instance = Vue.prototype.$auth
  try {
    await store.dispatch('user/getUserIfNotExist', false)
  } catch {
    auth0Instance.logout({ returnTo: `${window.location.origin}/error-403` })
    return
  }
  await store.dispatch('user/getAllUserGroupsIfNotPresent', false)
}

const waitForAuth0ToBeInitialized = async () => {
  const appInitialized = store.getters['app/getAuth0Initialized']
  if (!appInitialized) {
    const instance = getInstance()
    await new Promise(resolve => {
      instance.$watch('loading', async loading => {
        if (loading === false) {
          await store.commit('app/SET_AUTH0_INITIALIZED')
          resolve('')
        }
      })
    })
  }
}
const checkForAuth0Authentication = async to => {
  if (to.query && to.query.logout) {
    Vue.prototype.$tabEvent.emit('logout-user')
    await Vue.prototype.$auth.logout({ localOnly: true })
    window.close()
  }
}
router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
router.beforeEach(async (to, from, next) => {
  if (to.path === '/creative-studio' && to.query.code) {
    localStorage.setItem('canvaCode', JSON.stringify(to.query.code))
    sessionStorage.setItem('canvaCode', JSON.stringify(to.query.code))
    window.location.href = '/creative-studio'
  }

  if (to.path === '/creative-studio') {
    const sessionKey = await findAuth0SessionKey(sessionStorage)
    if (sessionKey) {
      const sessionValue = sessionStorage.getItem(sessionKey)
      localStorage.setItem(`${sessionKey}`, sessionValue)
    }
    const sessionCanvaCode = sessionStorage.getItem('canvaCode')
    if (sessionCanvaCode) {
      localStorage.setItem('canvaCode', sessionCanvaCode)
    }
  }
  const { groupId } = to.query
  await waitForAuth0ToBeInitialized()
  await checkForAuth0Authentication(to)
  const auth0Instance = Vue.prototype.$auth

  if (groupId) {
    await setSelectedGroupId(Number(groupId))
  }
  if (to.meta.requireAuth) {
    if (!auth0Instance.isAuthenticated) {
      next('/login')
    } else {
      await fetchUserDataIfRequired()
      // await setSelectedGroupIdIfNotProvided()
      next()
    }
  } else if (auth0Instance.isAuthenticated && to.meta.publicOnly) {
    next({
      path: '/',
    })
  }
  next() // make sure to always call next()!
})

export default router
